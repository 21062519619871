import React from 'react';
import './companies.css';
import {useAppContext} from "../appContext";
import {useNavigate} from "react-router-dom";

export default function CompanyItem({ company }) {
    const navigator = useNavigate();
    const { globalProps, visitProps, updateGlobalProps, updateVisitProps } = useAppContext();
 console.log('CompanyItem')

    const clearVisit = () => {
        console.log('clearVisit')

        updateVisitProps( {
            id: null,
            visit_hash: null,
            status: null,
            name: null,
            company: "-",
            email: "email@email.com",
            phone: "-",
            company_hash: null,
            visitor_hash: null,
            host_id: null,
            host_name: null
        })
    }

    const handleCompanyCLick = (company) => {
        console.log('handleCompanyCLick', company)
        console.log('globalProps', globalProps.action)
        updateGlobalProps({
            company: company,
            action: 'create'
        })
        navigator('/main');
        clearVisit();
    }

    return (
        <button key={company.id}
             className='item-card'
             onClick={() => {handleCompanyCLick(company)} }
             onTouchStart={() => {handleCompanyCLick(company)} } >
            {company.logoOriginal && <img src={"https://app.visitbook.io/uploads/company/" + company.logoOriginal} alt={company.name} />}
        </button>
    );
}
