import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import Background from "../header/Background";
import "./visit.css";
import StatusMenu from "../statusmenu/StatusMenu";
import lastScanImg from "./images/reader.png";
import ticketImg from "./images/ticket.png";
import scanVideo from "./video/scan-video.mp4";
import { useAppContext } from "../appContext";
import Spinner from "../spinner/spinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Main() {
  const { globalProps, visitProps, updateVisitProps, updateGlobalProps } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [waiting, setWaiting] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  console.log('====render Finish=====')
  console.log('finish global props', globalProps)
  console.log('finish globalProps.action', globalProps.action)

  useEffect(() => {
    console.log('global props', globalProps)
    console.log('useEffect1')
    if (!globalProps.company) {
      console.log('useEffect1', 'navigate("/companies")')
      navigate("/companies");
    }
    if (!visitProps.visitor_hash) {
      console.log('useEffect1', 'navigate("/companies")')
      navigate("/companies");
    }

    if (globalProps.action === "create") {
      console.log('useEffect1', 'createVisit()')
      createVisit();
    } else if (globalProps.action === "update"){
      console.log('useEffect1', 'updateVisit()')
      updateVisit();
    }

    const timeoutId = setTimeout(() => {
      navigate("/companies");
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [globalProps]);

  useEffect(() => {
    console.log('useEffect2')
    console.log('global props', globalProps)
    if (globalProps.company.settings?.confirm_scenario === '1') {
      const waitTimer = setTimeout(() => {
        setWaiting(false);
      }, 3000);

      return () => clearTimeout(waitTimer);
    }
  }, [globalProps]);

  // Попередній код без консолей
  // const createVisit = async () => {
  //     try {
  //         axios({
  //             method: 'POST',
  //             url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/create" ,
  //             headers: {},
  //             data: globalProps.visit
  //         })
  //         .then((result) => {
  //             axios({
  //                 method: 'POST',
  //                 url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/update" ,
  //                 headers: {},
  //                 data: {
  //                     visit_hash: result.data.visit,
  //                     status: (globalProps.company.settings.confirm_scenario == 1 ? 10 : 20)
  //                 }
  //             })
  //             .then((result) => {
  //                 setIsLoading(false)
  //             })
  //         })
  //     } catch (error) {
  //         console.error('Error sending image:', error);
  //     }
  // };

  // З консоллю
  // const createVisit = async () => {
  //   console.log("Виконується функція createVisit...");
  //   console.log('visitProps', visitProps)
  //   console.log('globalProps', globalProps)
  //   try {
  //     console.log("Виконання блоку try...");
  //
  //   axios({
  //       method: "POST",
  //       url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/create",
  //       headers: {},
  //       data: visitProps,
  //     }).then((result) => {
  //       console.log("Блок then. Результат запиту create (result):", result);
  //       console.log("Результат create: result.data:", result?.data, result?.data.error);
  //       console.log("Початок виконання запиту update...");
  //
  //       if (result.status === 404) {
  //         console.log("Помилка 404. Запит create. Показуємо Alert.");
  //         alert("Візит не створено. Спробуйте ще раз.");
  //       }
  //
  //       if (!result.data.visit) {
  //         console.log(
  //           `Вихід із функції createVisit та навігація на /companies, оскільки відсутнє значення result.data.visit: ${result.data.visit}...`
  //         );
  //         navigate("/companies");
  //         setIsLoading(false);
  //         return;
  //       }
  //
  //       console.log('update in create, visit_hash=',result.data.visit )
  //
  //       axios({
  //         method: "POST",
  //         url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/update",
  //         headers: {},
  //         data: {
  //           visit_hash: result.data.visit,
  //           status:
  //             globalProps.company.settings.confirm_scenario == 1 ? 10 : 20,
  //         },
  //       }).then((result) => {
  //         console.log("Блок then. Результат запиту update (result):", result);
  //         console.log("Результат update: result.data:", result?.data);
  //
  //         if (result.status === 404) {
  //           console.log("Помилка 404. Запит update. Показуємо Alert.");
  //           alert("Візит не створено. Спробуйте ще раз.");
  //         }
  //
  //         //console.log('globalprops', globalProps, 'visitprops', visitProps)
  //         setIsLoading(false);
  //
  //       });
  //     });
  //   } catch (error) {
  //     console.error("Виконання блоку catch...");
  //     console.error("Помилка:", error);
  //     Sentry.captureException(error);
  //   }
  // };

  const createVisit = async () => {
    console.log("Виконується функція createVisit...");

    console.log(
        "visitProps під час виклику функції createVisit", visitProps
    );

    try {
      console.log("Виконання блоку try...");
      console.log("Початок виконання запиту create...");

      console.log(
          "visitProps перед виконанням запиту", visitProps
      );

      const createResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/apiClient/visit/create`,
          visitProps
      );

      console.log("Результат виконання запиту create:", createResponse.data);

      if (createResponse.status !== 200) {
        console.error(
            "Помилка під час створення візиту:",
            createResponse.status
        );
        setIsLoading(false);
       // return;
        navigate('/companies')
      }

      console.log(
          "Значення visit_hash перед виконанням запиту update: createResponse.data.visit:",
          createResponse.data.visit
      );

      console.log(
          "Значення status перед виконанням запиту update: globalProps.company.settings.confirm_scenario === 1 ? 10 : 20:",
          globalProps.company.settings.confirm_scenario === '1' ? 10 : 20
      );

      const updateResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/apiClient/visit/update`,
          {
            visit_hash: createResponse.data.visit,
            status: globalProps.company.settings.confirm_scenario === '1' ? 10 : 20,
          }
      );

      console.log("Результат виконання запиту update:", updateResponse.data);

      if (updateResponse.status !== 200) {
        console.error(
            "Помилка під час оновлення візиту:",
            updateResponse.status
        );
        setIsLoading(false);
        navigate('/companies')
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Виконання блоку catch...");
      console.error("Помилка:", error);
      Sentry.captureException(error);
      navigate('/companies')
    }
  };

  const updateVisit = async () => {
    console.log('updateVisit, visitProps:', visitProps)
    console.log('globalProps:', globalProps)
    try{
     const updateResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/apiClient/visit/update`,
          {
            visit_hash: visitProps.visit_hash,
            status: globalProps.company.settings.confirm_scenario === '1' ? 10 : 20,
          }
      );

        console.log("Результат виконання запиту update:", updateResponse.data);

      if (updateResponse.status !== 200) {
        console.error(
            "Помилка під час оновлення візиту:",
            updateResponse.status
        );
        setIsLoading(false);
        navigate('/companies')
      }

      setIsLoading(false);
    }
    catch(error){
      console.error('catch update error', error)
      Sentry.captureException(error);
      navigate('/companies')
    }
  //   finally {
  //       console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!finally update')
  //       updateGlobalProps({action: 'create'})
  // }
    // axios({
    //   method: "POST",
    //   url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/update",
    //   headers: {},
    //   data: {
    //     visit_hash: visitProps.visit_hash,
    //     status: globalProps.company.settings.confirm_scenario == 1 ? 10 : 20,
    //   },
    // }).then((result) => {
    //   setIsLoading(false);
    // });
  };

  return (
    <>
      {isLoading ? (
            <Spinner />
      ) : (
        <Background>
          <div className="wrapper">
            {globalProps.company.settings.confirm_scenario === '1' ? (
              <div className="finish-text-wrap whait">
                {waiting ? (
                  <>
                    <div className="finish-text">
                      <h2>Очікуйте підтвердження та отримайте перпустку</h2>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="scan-steps">
                      <div className="last-scan-step">
                        <video className="scan-video" muted loop autoPlay>
                          <source src={scanVideo} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="finish-text-wrap">
                <div className="finish-text">
                  <div className="scan-steps">
                    <div className="last-scan-step">
                      <video className="scan-video" muted loop autoPlay>
                        <source src={scanVideo} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Background>
      )}
    </>
  );
}
