import React, {FormEvent, useEffect, useState} from 'react';
import Background from "../header/Background";
import './visit.css';
import StatusMenu from "../statusmenu/StatusMenu";
import TopMenu from "./TopMenu";
import {useAppContext} from "../appContext";
import {useNavigate} from "react-router-dom";
import Rules from "../rules";
import axios from "axios";
import Spinner from "../spinner/spinner";
import bookImage from "./images/contact-book.svg";

export default function Main(company: object) {

    const navigate = useNavigate();
    const { globalProps, visitProps, updateGlobalProps, updateVisitProps } = useAppContext();
    const [companyName, setCompanyName] = useState(visitProps.company);
    const [email, setEmail] = useState(visitProps.email);
    const [phone, setPhone] = useState(visitProps.phone);
    const [name, setName] = useState(visitProps.name===null? "": visitProps.name);
    const [host, setHost] = useState(visitProps.host_name===null? "": visitProps.host_name);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    console.log('render Main',globalProps.company )
    //console.log('globalprops', globalProps, 'visitprops', visitProps)


    useEffect(() => {
        if (globalProps.company == null) { navigate('/companies') }

    }, [])

    useEffect(() => { //!
        updateVisitProps({
            name: name.trim(),
            phone: (globalProps.company.settings.identification_phone == 1 ? phone : '---'),
            email: (globalProps.company.settings.identification_email == 1 ? email : 'no-email@mail.com'),
            company: (globalProps.company.settings.identification_company == 1 ? companyName : globalProps.company.name)
        })

    }, [name, phone, email, companyName, globalProps])

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        if (phone && email && name && host && globalProps.rulesAgreement) {
            try {
                const response = await axios({
                    method: 'get',
                    url: process.env.REACT_APP_BACKEND_URL + '/apiClient/visitor/item?company_id='
                        + globalProps.company.id
                        + '&name=' + name,
                    headers: {},
                });

                if (response.status >= 200 && response.status < 300) {
                    updateVisitProps({
                        visitor_hash: response.data,
                        status:(globalProps.company.settings.confirm_scenario == 1 ? 'ON-SITE' : 'Check-IN')
                    })


                    setData(response.data);
                    console.log(response.data);
                    setIsLoading(false);
                    setIsFilled(true);

                    if('COMPANY_SETTING_IS_PHOTOBLE' in globalProps.company.settings){
                        if(globalProps.company.settings.COMPANY_SETTING_IS_PHOTOBLE == 1){
                            navigate('/photo');
                        }else{
                            navigate('/finish');
                        }
                    }else {
                        navigate('/finish');
                    }

                }

            } catch (error) {
                console.error(error);
                setIsLoading(true);
            }

        } else {
            setIsFilled(false);
            if (!name) {
                document.getElementById('name')?.classList.add('input-error');
            }
            if ((globalProps.company.settings.identification_phone == 1) && (!phone)) {
                document.getElementById('phone')?.classList.add('input-error');
            }
            if ((globalProps.company.settings.identification_email == 1) && (!email)) {
                document.getElementById('email')?.classList.add('input-error');
            }
            if ((globalProps.company.settings.identification_company == 1) && (!companyName)) {
                document.getElementById('companyName')?.classList.add('input-error');
            }
            if (!host) {
                document.getElementById('company')?.classList.add('input-error');
            }
            if (!globalProps.rulesAgreement) {
                document.getElementById('rules')?.classList.add('input-error');
            }
            setIsLoading(false);
        }

    };

    const handleVisiter = () => {
        if('check_in_by_name' in globalProps.company.settings){
            if(globalProps.company.settings.check_in_by_name == 0){
                navigate('/visiter');
            }
        }
    }
    const handleBook = () => {
        navigate('/visiter');
    }

    const handleHost = () => {
        navigate('/host');
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateGlobalProps({
            rulesAgreement: event.target.checked
        })
        //globalProps.rulesAgreement = event.target.checked;
        if (event.target.checked) {
            document.getElementById('rules')?.classList.remove('input-error');
        }
    };

    useEffect(() => {
        updateVisitProps({
            company_hash: globalProps.company.hash,
            name: name.trim()
        })
       // globalProps.visit.company_hash = globalProps.company.hash;
       // globalProps.visit.name = name;
    }, [name]);

    const formatPhoneNumber = (value: string) => {
        const cleaned = value.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,6})$/);

        if (match) {
            let formattedValue = '+380';
            if (match[2]) {
                formattedValue += match[2];
            }
            if (match[3]) {
                formattedValue += match[3];
            }
            return formattedValue;
        }
        return value;
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = event.target.value.replace(/\D/g, '');
        if (rawValue.length <= 12) {
            const formattedValue = formatPhoneNumber(event.target.value);
            setPhone(formattedValue);
            document.getElementById('phone')?.classList.remove('input-error');
        }
    };


    return (
        <>
            {isLoading ? (<Spinner />) : (
            <div className="inner-page">
                <Background>
                    <TopMenu link={'/companies'} />
                    <div className="wrapper">
                        <form className="contact-form main" onSubmit={handleSubmit}>
                            <input
                                type="text"
                                id="name"
                                placeholder="Введіть ваше ім'я та прізвище"
                                value={name}
                                onFocus={handleVisiter}
                                onChange={(event) => {
                                    setName(event.target.value);
                                    document.getElementById('name')?.classList.remove('input-error')
                                }}
                            />
                            {globalProps.company.settings.identification_phone == 1 && (
                                <input
                                    type="text"
                                    id="phone"
                                    placeholder="Введіть ваш телефон"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                />
                            )}
                            {globalProps.company.settings.identification_email == 1 && (
                                <input
                                    type="text"
                                    id="email"
                                    placeholder="Введіть ваш email"
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                        document.getElementById('email')?.classList.remove('input-error')
                                    }}
                                />
                            )}
                            {globalProps.company.settings.identification_company == 1 && (
                                <input
                                    type="text"
                                    id="companyName"
                                    placeholder="Введіть вашу компанію"
                                    value={companyName}
                                    onChange={(event) => {
                                        setCompanyName(event.target.value);
                                        document.getElementById('companyName')?.classList.remove('input-error')
                                    }}
                                />
                            )}
                            <img className="contact-book" src={bookImage} alt="book" onClick={handleBook}/>
                            <input
                                type="text"
                                id="company"
                                placeholder="Введіть до кого"
                                value={host}
                                onFocus={handleHost}
                                onChange={(event) => {
                                    setHost(event.target.value);
                                    document.getElementById('company')?.classList.remove('input-error')
                                }
                                }
                            />
                            <Rules handleCheckboxChange={handleCheckboxChange}/>
                            <button type="submit">Далі</button>
                        </form>
                    </div>
                    <StatusMenu active={[0, 1]}/>
                </Background>
            </div>
            )}
        </>
    );
}
