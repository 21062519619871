import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
    const [globalProps, setGlobalProps] = useState({
        company: null,
        action: 'create',
        rulesAgreement: true
    });
    const [visitProps, setVisitProps] = useState({
        id: null,
        visit_hash: null,
        status: null,
        name: null,
        company: "-",
        email: "email@email.com",
        phone: "-",
        company_hash: null,
        visitor_hash: null,
        host_id: null,
        host_name: null
    });

    console.log('APP PROVIDER globalProps:', globalProps)
    console.log('APP PROVIDER visitProps:', visitProps)

    const updateGlobalProps = (newProps) => {
        console.log('updateGlobalProps', newProps)
        setGlobalProps((prevState)=>({
            ...prevState,
            ...newProps,
        }));
    };
    const updateVisitProps = (newProps)=> {
        console.log('updateVisitProps', newProps)
        setVisitProps((prevState)=> ({
        ...prevState,
        ...newProps,
        }))
    };

    return (
        <AppContext.Provider value={{ globalProps, visitProps, updateGlobalProps, updateVisitProps }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
